import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Color3, Color4} from "@babylonjs/core/Maths/math.color";
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";
import { Animation } from "@babylonjs/core/Animations/animation";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { AutoRotationBehavior, FramingBehavior } from "@babylonjs/core/Behaviors";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { toDataURL as CreateQRCode } from 'qrcode';

// required imports
import "@babylonjs/core/Loading/loadingScreen";
import "@babylonjs/loaders/glTF";
import "@babylonjs/core/Materials/standardMaterial";
import "@babylonjs/core/Materials/Textures/Loaders/envTextureLoader";
import "@babylonjs/core/Animations/animatable";
import "@babylonjs/core/Helpers/sceneHelpers";

import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { PBRMaterial } from "@babylonjs/core";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Nullable } from "@babylonjs/core/types";
import { BoundingSphere } from "@babylonjs/core/Culling/boundingSphere";
import { BoundingBox } from "@babylonjs/core/Culling/boundingBox";
import { Camera } from "@babylonjs/core/Cameras/camera";

interface Options {
    targetCanvas: string;
    model: string;
    type: string;
    isOpen: boolean;
}

export interface IData {
    id: string;
    name: string;
    type: string;
    hotspots: IHotspot[];
}

export interface IHotspot {
    id: string;
    type: string;
    animationActivateStart: number;
    animationActivateEnd: number;
    animationDeactivateStart: number;
    animationDeactivateEnd: number;
    activated?: boolean;
    mesh?: AbstractMesh;
    video?: string;
    start?: string;
    end?: string;
}

class GunneboViewer {
    actionPlaying: boolean = false;
    isOpen: boolean = false;
    fadeOutanimation: Animation = new Animation('fadeOut', 'scaling', 60, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE);

    constructor(options: Options) {
        const container = document.getElementById(options.targetCanvas) as HTMLElement;
        container.style.position = 'relative';

        const canvas = document.createElement("canvas");
        canvas.setAttribute("touch-action", "none");
        canvas.style.width = '100%';
        canvas.style.height = '100%';
        canvas.style.touchAction = 'none';
        container.appendChild(canvas);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.ar == options.model) {
            this.isOpen = (params.open === "1") ? true : false;
            this.gotoAr(options, container);
        }

        // Generate the BABYLON 3D engine
        let engine = new Engine(canvas, true);

        // Create the scene
        const scene = new Scene(engine);  
       // scene.debugLayer.show({ embedMode: true });
        
        const camera = new ArcRotateCamera(
            "Main",
            Math.PI / 3,
            Math.PI * 0.5,
            1,
            new Vector3(0, 0, 0),
            scene
        );
        camera.fov = 0.27;              

        camera.useFramingBehavior = true;

        var framingBehavior = camera.getBehaviorByName("Framing") as Nullable<FramingBehavior>;
        if (framingBehavior) {
            framingBehavior.framingTime = 0;
            framingBehavior.radiusScale = 0.7;          
            framingBehavior.elevationReturnTime = -1;
        }

        // This targets the camera to scene origin
        camera.setTarget(Vector3.Zero());

        if (typeof(options.type) !== "undefined" && options.type == "videoHotspots") {
            camera.pinchPrecision = 0.5;
            camera.wheelPrecision = 1;
            camera.panningSensibility = 0;

            scene.onBeforeRenderObservable.add(() => {        
                scene.meshes.forEach(mesh => {
                    if (mesh.id.includes("Hotspot_")) {
                        mesh.visibility = (camera.radius < 30) ? 0 : 1;
                    }
                })
            })

        } else {
            camera.wheelPrecision = 50;
        }

        camera.useAutoRotationBehavior = true;
        var mouseWheel = camera.inputs.attached.mousewheel;
        camera.inputs.remove(mouseWheel);

        var autoRotationBehavior = new AutoRotationBehavior();
        autoRotationBehavior.idleRotationSpeed = -0.1;
        autoRotationBehavior.idleRotationWaitTime = 0;       
        autoRotationBehavior.idleRotationSpinupTime = 1;
        camera.addBehavior(autoRotationBehavior);
       
        camera.panningSensibility = 0;
        camera.panningAxis = Vector3.Zero();

        // This attaches the camera to the canvas
        camera.attachControl(canvas);

        camera.useBouncingBehavior = false;

        var environmentTexture = CubeTexture.CreateFromPrefilteredData(window.STUDIO_ENV, scene);
        scene.clearColor = new Color4(1, 1, 1, 1);
        scene.environmentTexture = environmentTexture;

       // var skybox = scene.createDefaultSkybox(environmentTexture, true, (camera.maxZ - camera.minZ / 2), 0.3, true);
       // skybox!.isVisible = false;

        var stopRotate = () => {
            if (camera.useAutoRotationBehavior) {
                camera.useAutoRotationBehavior = false;
                camera.removeBehavior(autoRotationBehavior);
                camera.inputs.add(mouseWheel);

                if (typeof(options.type) !== "undefined" && options.type == "videoHotspots") {
                    camera.panningSensibility = 500; 
                }
            }
        }

        canvas.onclick = stopRotate
        canvas.ontouchstart = stopRotate

        let keyframes = [
            {
                frame: 0,
                value: new Vector3(1, 1, 1)
            },
            {
                frame: 10,
                value: new Vector3(0, 0, 0)
            }
        ];

        this.fadeOutanimation.setKeys(keyframes);

        var jsonFilePath = window.JSON_PATH.replace("{0}", options.model);
        jsonFilePath = jsonFilePath.replace("{0}", options.model);

        fetch(jsonFilePath)
            .then(e => e.json() as Promise<IData>)
            .then(e => {                

                var glbFilePath = window.GLB_PATH.replace("{0}", options.model);
                glbFilePath = glbFilePath.replace("{1}", options.model);

                SceneLoader.ImportMeshAsync(
                    "",
                    glbFilePath,
                    "",
                    scene
                ).then((result) => { 
         
                    if (typeof(options.type) !== "undefined" && options.type === "videoHotspots") {
                        
                        var boundingInfo = scene.getWorldExtends(function (mesh) {
                            return mesh.isVisible && mesh.isEnabled();
                         });
                         var bb = new BoundingBox(boundingInfo.min, boundingInfo.max);
                         camera.setTarget(bb.center);

                        camera.panningAxis = new Vector3(1,0,1); 
                        camera.panningDistanceLimit = 8; 
                        camera.panningInertia = 0.5;                   
                        camera.useNaturalPinchZoom = true;            
                        camera.inertia = 0.01;
                        camera.lowerBetaLimit = 0;
                        camera.upperBetaLimit = Math.PI/2;
                        camera.beta = Math.PI/3;
                    
                        camera.lowerRadiusLimit = camera.minZ;
                        camera.upperRadiusLimit = camera.minZ * 6; 
                    } else {
                        camera.zoomOnFactor = 2.7;
                        camera.zoomOn(result.meshes);    
                        camera.lowerRadiusLimit = camera.minZ * 3;
                        camera.upperRadiusLimit = camera.minZ * 7;  
                    }

                    camera.minZ *= 0.1;
                    camera.maxZ *= 20;                   
                    
                    var sceneCam = result.transformNodes.find(e => e.name == "Main");
                    if (sceneCam) {
                        camera.setPosition(sceneCam.absolutePosition);
                    }

                    if (scene.animationGroups.length > 0) {
                        scene.animationGroups[0].reset();
                        scene.animationGroups[0].stop();
                    }

                    var hotspots = e.hotspots;

                    for (let index = 0; index < scene.meshes.length; index++) {
                        const mesh = scene.meshes[index];
                        mesh.isPickable = false;
                    }

                    for (let index = 0; index < hotspots.length; index++) {
                        const hotspot = hotspots[index];

                        let hotspotMaterial = scene.getMaterialByName("hotspotMaterial_" + hotspot.type) as StandardMaterial;
                        if (!hotspotMaterial) {
                            hotspotMaterial = new StandardMaterial("hotspotMaterial_" + hotspot.type, scene);
                            hotspotMaterial.backFaceCulling = false;
                        }

                        if (hotspotMaterial) {

                            let hotspotTexture = scene.getTextureByName("hotspotTexture_" + hotspot.type);
                            if (!hotspotTexture) {
                                var hotspotTexturePath = window.HOTSPOT_PATH.replace("{0}", hotspot.type);
                                hotspotTexture = new Texture(hotspotTexturePath, scene);
                                hotspotTexture.hasAlpha = true;
                            }

                            if (hotspotTexture) {
                                hotspotMaterial.diffuseTexture = hotspotTexture;
                                hotspotMaterial.specularColor = new Color3(0, 0, 0);
                                hotspotMaterial.opacityTexture = hotspotTexture;
                                hotspotMaterial.emissiveColor = new Color3(1, 1, 1);
                                this.createHotspot(scene, camera, hotspot, e, options, container, hotspotMaterial);
                            }
                        }
                    }

                    onResize();
                });
            })

        const onResize = () => {
        
            var boundingInfo = scene.getWorldExtends(function (mesh) {
               return mesh.isVisible && mesh.isEnabled();
            });
            var bs = new BoundingSphere(boundingInfo.min, boundingInfo.max);
            let radius = bs.radiusWorld*0.9;        
            if (radius === Infinity) {
                return;
            }

            let aspectRatio = engine.getAspectRatio(camera);
            let halfMinFov = camera.fov / 2;
            if (aspectRatio < 1) {
                halfMinFov = Math.atan(aspectRatio * Math.tan(camera.fov / 2))
            }

            let viewRadius = Math.abs(radius / Math.sin(halfMinFov));

            camera.upperRadiusLimit = viewRadius;
            camera.radius = viewRadius;

            console.log("cameraradius: "+ camera.radius);

           /* if (framingBehavior) {
                if (scene.meshes.length) {
                    var worldExtends = scene.getWorldExtends(function (mesh) {                        
                        return mesh.isVisible && mesh.isEnabled();
                    });                 
                    framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max);
                }
            }*/

            engine.resize();
        }

        // Register a render loop to repeatedly render the scene
        engine.runRenderLoop(function () {
            scene.render();
        });

        // Watch for browser/canvas resize events
        window.addEventListener("resize", onResize);

        let portrait = window.matchMedia("(orientation: portrait)");
        portrait.addEventListener("change", function (e) {
            onResize();
        });

        if (options.type !== "videoHotspots" || typeof(options.type) === "undefined") {
            const arButton = document.createElement('a');
            arButton.onclick = () => this.gotoAr(options, container);
            arButton.style.border = "none";
            arButton.style.background = "#fff";
            arButton.style.position = "absolute";
            arButton.style.top = "10px";
            arButton.style.right = "10px";
            arButton.style.padding = "0";
            arButton.style.cursor = "pointer";

            const arButtonIcon = document.createElement('img');
            arButtonIcon.src = window.AR_ICON_PATH;
            arButtonIcon.style.width = "3rem";
            arButton.appendChild(arButtonIcon);
            container.appendChild(arButton);
        }
    }

    gotoAr = async (options: Options, container: HTMLElement) => {
        var platform = this.getPlatform();
        var arButton = document.createElement("a");

        var AROpenSuffix = this.isOpen ? "_AR_Open" : "_AR_Closed";

        if (platform == 'android') {

            var glbFilePath = window.GLB_PATH.replace("{0}", options.model);
            glbFilePath = glbFilePath.replace("{1}", options.model + AROpenSuffix);

            var glbFileUrl = new URL(glbFilePath, document.baseURI)
            arButton.setAttribute("rel", "ar");
            arButton.appendChild(document.createElement('img'));
            arButton.setAttribute(
                "href",
                "intent://arvr.google.com/scene-viewer/1.0?file=" + encodeURIComponent(glbFileUrl.href) + "?mode=ar_preferred#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=%23;end;"
            );
            arButton.click();
            arButton.remove();
        } else if (platform == "ios" || platform == 'ipad' || platform != 'ipad') {

            var usdzFilePath = window.USDZ_PATH.replace("{0}", options.model);
            usdzFilePath = usdzFilePath.replace("{1}", options.model + AROpenSuffix);

            var usdzFileUrl = new URL(usdzFilePath, document.baseURI)
            arButton.setAttribute("rel", "ar");
            arButton.appendChild(document.createElement('img'));
            arButton.setAttribute("href", usdzFileUrl.href);
            arButton.click();
            arButton.remove();

        } else {

            const url = new URL(window.location.href);
            url.searchParams.append('ar', options.model);
            url.searchParams.append('open', this.isOpen ? "1" : "0");

            const qrcode = await CreateQRCode(url.href, {});

            const popup = document.createElement('div');
            popup.style.position = "absolute";
            popup.style.top = "50%";
            popup.style.left = "50%";
            popup.style.border = "1px solid #000";
            popup.style.borderRadius = "3px";
            popup.style.background = "#fff";
            popup.style.transform = "translate(-50%, -50%)";
            popup.style.textAlign = "center";
            popup.style.padding = "10px";

            const text = document.createElement('p');
            text.innerHTML = 'Scan this QR code with your phone to view the object in your space.';

            const qrImage = document.createElement('img');
            qrImage.setAttribute('src', qrcode);

            const closeBtn = document.createElement('button');
            closeBtn.innerHTML = "Close";
            closeBtn.style.border = "1px solid #000";
            closeBtn.style.borderRadius = "3px";
            closeBtn.style.background = "#fff";
            closeBtn.style.padding = "10px";
            closeBtn.style.cursor = "pointer";
            closeBtn.onclick = () => container.removeChild(popup);


            popup.appendChild(text);
            popup.appendChild(qrImage);
            popup.appendChild(document.createElement('br'));
            popup.appendChild(closeBtn);
            container.appendChild(popup);
        }
    }


    getPlatform = () => {
        var IS_ANDROID = /android/i.test(navigator.userAgent);
        var IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !("MSStream" in self);
        var IS_IOS_12_OR_GREATER = IS_IOS && /OS 1([2-9])/.test(navigator.userAgent);
        var IS_ANDROID_7_OR_GREATER = /android ([7-9]|[1-9][0-9])/i.test(navigator.userAgent);
        var IS_IOS_CHROME = IS_IOS && /CriOS\//.test(navigator.userAgent);
        var IS_IOS_SAFARI = IS_IOS && /Safari\//.test(navigator.userAgent);
        var IPAD = (navigator.platform == 'MacIntel' && navigator.maxTouchPoints > 1);
        var IS_ANDROID_BELOW_7 = /android ([0-6]|[1-9][0-9])/i.test(navigator.userAgent);
        var IS_IOS_BELOW_12 = IS_IOS && /OS [0-9]|10|11/.test(navigator.userAgent);

        if (IS_ANDROID_7_OR_GREATER) {
            return 'android';
        } else if ((IS_IOS_CHROME || IS_IOS_SAFARI) && IS_IOS_12_OR_GREATER) {
            return 'ios';
        } else if (IPAD) {
            return 'ipad';
        } else if (IS_ANDROID_BELOW_7 || IS_IOS_BELOW_12) {
            return 'not_supported';
        }
        return 'desktop';
    }

    activateHotspot = (scene: Scene, container: HTMLElement, options: Options, hotspot: IHotspot) => {

        switch (hotspot.type) {

            case "animation": {

                this.actionPlaying = true;

                var start = hotspot.animationActivateStart;
                var end = hotspot.animationActivateEnd;

                if (hotspot.activated) {
                    start = hotspot.animationDeactivateStart;
                    end = hotspot.animationDeactivateEnd;

                    hotspot.activated = false;
                } else {
                    hotspot.activated = true;
                }
                if (hotspot.mesh) {
                    scene.beginAnimation(hotspot.mesh, 0, 10, false);
                }

                start = (start / 30) * 60;
                end = (end / 30) * 60;

                scene.animationGroups[0].stop();
                scene.animationGroups[0].start(false, start < end ? 1 : -1, start, end);

                scene.animationGroups[0].onAnimationGroupEndObservable.addOnce(() => {
                    this.actionPlaying = false;

                    if (hotspot.mesh) {
                        if (hotspot.activated) {
                            this.isOpen = true;
                            hotspot.mesh.rotate(Vector3.Backward(), Math.PI * 0.25);
                        } else {
                            this.isOpen = false;
                            hotspot.mesh.rotate(Vector3.Backward(), -Math.PI * 0.25);
                        }
                        scene.beginAnimation(hotspot.mesh, 10, 0, false, -1);
                    }
                });
            }
                break;
            case "video": {

                var videoWrapper = document.getElementById("video-wrapper") as HTMLDivElement;
                var videoOverlay = null;
                var video = null;
                var videoBg = null;

                if (!videoWrapper) {

                    videoWrapper = document.createElement("div") as HTMLDivElement;
                    videoWrapper.id = "video-wrapper";
                    videoWrapper.style.display = "none";

                    videoBg = document.createElement("div") as HTMLDivElement;
                    videoBg.style.position = "fixed";
                    videoBg.style.top = "0";
                    videoBg.style.left = "0";
                    videoBg.style.bottom = "0";
                    videoBg.style.right = "0";
                    videoBg.style.backgroundColor = "rgba(0,0,0,0.75)";
                    videoBg.style.zIndex = "9";
                    videoBg.onclick = () => {
                        var videoWrapper = document.getElementById("video-wrapper") as HTMLDivElement;
                        videoWrapper.style.display = "none";
                    }

                    videoOverlay = document.createElement("div") as HTMLDivElement;
                    videoOverlay.id = "video-overlay";
                    videoOverlay.style.position = "absolute";
                    videoOverlay.style.top = "50%";
                    videoOverlay.style.left = "50%";
                    videoOverlay.style.transform = "translate(-50%,-50%)";
                    videoOverlay.style.width = "800px";
                    videoOverlay.style.height = "450px";
                    videoOverlay.style.backgroundColor = "#FFF";
                    videoOverlay.style.boxShadow = "0px 0px 10px -4px rgba(0,0,0,0.75)";
                    videoOverlay.style.zIndex = "10";

                    var videoClose = document.createElement("a");
                    videoClose.style.position = "absolute";
                    videoClose.style.right = "-15px";
                    videoClose.style.top = "-15px";
                    videoClose.style.width = "30px";
                    videoClose.style.height = "30px";
                    videoClose.style.display = "flex";
                    videoClose.style.borderRadius = "30px";
                    videoClose.style.cursor = "pointer";
                    videoClose.style.justifyContent = "center";
                    videoClose.style.alignItems = "center";
                    videoClose.style.background = "#FFF";
                    videoClose.style.border = "1px solid #ADADAD";

                    var closeOverlay = () => {
                        var videoWrapper = document.getElementById("video-wrapper") as HTMLDivElement;
                        videoWrapper.style.display = "none";
                    }

                    videoClose.onclick = closeOverlay;

                    videoClose.innerHTML = "<svg style=\"width: 20px; height: 20px\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 320 512\"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d=\"M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z\"/></svg>";

                    video = document.createElement("video") as HTMLVideoElement;
                    video.muted = true;
                    video.loop = false;
                    video.autoplay = true;
                    video.style.opacity = "0";
                    video.style.transition = "opacity .2s ease-out";

                    video.onload = function(e: any) {
                        var elem = e.currentTarget as HTMLVideoElement;
                        if (elem) {
                           elem.style.opacity = "0";
                        }
                    }
                    video.onloadeddata = function(e: any) {
                         e.currentTime = 50
                         var elem = e.currentTarget as HTMLVideoElement;
                         if (elem) {
                            elem.style.opacity = "1";
                         }
                    }

                    video.onended = closeOverlay;
                    video.onpause = closeOverlay;

                    video.style.width = "100%";
                    video.style.height = "100%";

                    videoOverlay.appendChild(video);
                    videoOverlay.appendChild(videoClose);

                    videoWrapper.appendChild(videoBg);
                    videoWrapper.appendChild(videoOverlay);

                    container.appendChild(videoWrapper);
                } else {
                    video = videoWrapper.querySelector("video") as HTMLVideoElement;
                }

                var videoFilePath = window.VIDEO_PATH.replace("{0}", options.model);
                videoFilePath = videoFilePath.replace("{1}", hotspot.video || "");

                video.src = videoFilePath + `#t=${hotspot.start},${hotspot.end}`;
                videoWrapper.style.display = "block";
            }

        }
    }

    createHotspot = (scene: Scene, camera: ArcRotateCamera, hotspot: IHotspot, data: IData, options: Options, container: HTMLElement, material: StandardMaterial) => {
        let hotspotTarget = scene.getTransformNodeByName(hotspot.id);
        let self = this;

        if (!hotspotTarget) {
            hotspotTarget = scene.getTransformNodeByName("Cam_" + hotspot.id + ".target");
        }

        if (!hotspotTarget) {
            hotspotTarget = scene.getTransformNodeByName("Cam_" + hotspot.id);
        }

        if (hotspotTarget) {

            const hotspotPosition = hotspotTarget.getAbsolutePosition();

            var hotspotSize = (data.type === "videohotspots") ? 1 : (camera.radius /100)*2.5;

            var hotspotMesh = MeshBuilder.CreatePlane(hotspot.id, { size: hotspotSize }, scene);

            hotspotMesh.renderingGroupId = (hotspot.type !== "animation") ? 1 : 0;            
            hotspotMesh.visibility = 1;
            hotspotMesh.position = hotspotPosition;
            hotspotMesh.billboardMode = Mesh.BILLBOARDMODE_ALL;
            hotspotMesh.isPickable = true;
            hotspotMesh.actionManager = new ActionManager(scene);
            hotspotMesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, function (e) {
                scene.hoverCursor = "pointer";
            }));
            hotspotMesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, function() {
                if (hotspot && !self.actionPlaying) {
                    self.activateHotspot(scene, container, options, hotspot);
                }
            }));
          
            hotspotMesh.material = material;

            hotspotMesh.animations = [this.fadeOutanimation];

            hotspot.mesh = hotspotMesh;
        }
    }
}

export function init(options: Options) {
    var viewer = new GunneboViewer(options)
}